import React from 'react';

type Filters = {
  codigo_suscripcion: string;
  payment_method: string;
  payment_option: string;
  subscr_status: string;
  cif_account: string;
  cif_consultant: string;
  invoice_account_nif: string;
  legal_name_account: string;
  descripcion: string;
  recurrency: string; 
  last_payment: string | null;
  next_payment: string | null;
  remuneration: string | null;
  item_description: string;
  pack_description: string;
};
 
interface FilterProps {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

const Filter: React.FC<FilterProps> = ({ filters, setFilters }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  return (
    <div className="filter-container">
      <input
        type="text"
        name="payment_method"
        value={filters.payment_method}
        onChange={handleInputChange}
        placeholder="Método de pago"
        className="form-control mb-2"
      />
      <select
        name="subscr_status"
        value={filters.subscr_status}
        onChange={handleInputChange}
        className="form-control mb-2"
      >
        <option value="">Seleccione Estado</option>
        <option value="Active">Active</option>
        <option value="Inactive">Inactive</option>
      </select>

      <input
        type="text"
        name="cif_consultant"
        value={filters.cif_consultant}
        onChange={handleInputChange}
        placeholder="Cuenta Principal"
        className="form-control mb-2"
      />
      <input
        type="text"
        name="cif_account"
        value={filters.cif_account}
        onChange={handleInputChange}
        placeholder="Cuenta Miembro"
        className="form-control mb-2"
      />

      <input
        type="text"
        name="invoice_account_nif"
        value={filters.invoice_account_nif}
        onChange={handleInputChange}
        placeholder="Cuenta Facturación"
        className="form-control mb-2"
      />

      <input
        type="text"
        name="item_description"
        value={filters.item_description}
        onChange={handleInputChange}
        placeholder="Descripcion del Item"
        className="form-control mb-2"
      />

      <input
        type="text"
        name="pack_description"
        value={filters.pack_description}
        onChange={handleInputChange}
        placeholder="Descripcion del Paquete"
        className="form-control mb-2"
      />           


    </div>
  );
};

export default Filter;
