import { API } from '../config/config';

const SubscriptionService = {
  getAllSubscriptions: (start = 0, limit = 10, filters = {},orderPayment= 'ASC') => {
    const params = {
      ...filters,
      start,
      limit,
      order_payment:orderPayment,
    };
    const token = localStorage.getItem('authToken');
    return API.get('/api/subscriptions', {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error fetching subscriptions:', error);
        throw error;
      });
  },
};

export default SubscriptionService;
